$(function () {
  $('form input:required').keyup(function () {
    let disabled = false;
    $('form input:required').each(function (e) {
      if ($('form input:required').eq(e).val() == '') {
        disabled = true;
      }
    });
    $('#submit-form').prop("disabled", disabled);
  });
});
